import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Calendar, MapPin } from 'react-feather';

import { BaseDataProps } from './types';
import * as S from './styles';

import { IconWrapper } from 'styles';
import { dateGlobalPattern } from 'utils/dateUtils';

const BaseData = ({ name, startDate, endDate, fullAddress, locationType, status, customDate}: BaseDataProps) => (
	<S.EventDataWrapper>
		<S.EventName>{name || <Skeleton />}</S.EventName>

		{status === 'FINISHED' && (<S.EventStatusBadge>Evento encerrado</S.EventStatusBadge>)}

		<S.TextCentralized>
			<IconWrapper height={25} width={25} marginRight={10}>
				<Calendar size={25} color={process.env.REACT_APP_COLOR_MAIN} />
			</IconWrapper>

			{(customDate && customDate.length > 0) ? customDate : (
				<S.DateWrapper>
					<S.Date>{startDate ? format(new Date(dateGlobalPattern(startDate)), "dd 'de' MMMM 'de' yyyy',' HH:mm", {locale: pt}) : <Skeleton height={20} width={100} />}</S.Date>
					<S.DateSeparator />
					<S.Date>{endDate ? format(new Date(dateGlobalPattern(endDate)), "dd 'de' MMMM 'de' yyyy',' HH:mm", {locale: pt}): <Skeleton height={20} width={100} />}</S.Date>
				</S.DateWrapper>
			)}

		</S.TextCentralized>

		<S.TextCentralized>
			<IconWrapper height={25} width={25} marginRight={10}>
				<MapPin size={25} color={process.env.REACT_APP_COLOR_MAIN} />
			</IconWrapper>
			{(locationType === 'VIRTUAL' ? 'Evento virtual' : fullAddress) || <Skeleton height={30} width={250} />}
		</S.TextCentralized>
	</S.EventDataWrapper>
);

export default BaseData;
