import { Reducer } from 'redux';
import {
	EventState,
	EventTypes,
	Event,
} from './types';

const initialEvent: Event = {
	id: '',
	name: '',
	url: '',
	featuredBanner: '',
	banner: '',
	startDate: '',
	endDate: '',
	description: '',
	fullAddress: '',
	locationName: '',
	locationType: '',
	status: '',
	tags: '',
	limitedByCpf: false,
	tickets: [],
	salesStartDateInSeconds: null,
	showCancellationDescription: false,
	hasFacebookPixel: false,
	hasMarketing: false,
	groupBySector: false,
	hasDiscount: false,
	isAccreditation: false,
	customDate: '',

	// seats
	seatsMapActived: false,
	seatMap: {
		seatsIoMapkey: '',
	},
};

const INITIAL_STATE: EventState = {
	data: initialEvent,
	loading: false,
	error: false,
};

const reducer: Reducer<EventState> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case EventTypes.GET_EVENT:
			return state;

		case EventTypes.LOAD_EVENT:
			return { ...state, data: action.payload.data };

		case EventTypes.LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				data: action.payload.data,
			};

		case EventTypes.LOAD_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};

		case EventTypes.CLEAR_EVENT:
			return {
				...state,
				error: false,
				data: initialEvent
			};

		default:
			return state;
	}
};

export default reducer;
