import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'react-loading-skeleton';

import { Container } from 'styles';
import { EventCardList } from 'components/home';
import { SectionTitle, EventCard } from 'components/standalone';
import { Event, HomeState } from 'store/ducks/home/types';
import { getUpcomingEvents } from '../../store/ducks/home/actions';
import { SwiperContainer } from 'styles/Swiper';

interface StateProps {
	home: HomeState;
}

const HomeUpcomingEvents = () => {
	const dispatch = useDispatch();
	const { upcomingEvents, loadingUpcomingEvents } = useSelector((state: StateProps) => state.home);

	useEffect(() => {
		dispatch(getUpcomingEvents());
	}, [dispatch]);

	return (
		<>
			<Container>
				{!loadingUpcomingEvents && !isMobile && upcomingEvents.onTheRise.length > 0 && (
					<>
						<SectionTitle text="Para curtir no fim de semana"  marginBottom={10} marginTop={20} size={20} />
						<EventCardList lineItems={4}>
							{upcomingEvents.onTheRise.map(({
								name,
								banner,
								thumbnail,
								startDate,
								locationName,
								locationType,
								url,
								seatsMapActived,
								hasFacebookPixel,
							}: Event) => (
								<EventCard
									key={url}
									name={name}
									banner={banner}
									thumbnail={thumbnail}
									startDate={startDate}
									locationName={locationName}
									locationType={locationType}
									url={url}
									seatsMapActived={seatsMapActived}
									short={false}
									main={false}
									hasFacebookPixel={hasFacebookPixel}
								/>
							))}
						</EventCardList>
					</>
				)}
				{loadingUpcomingEvents && !isMobile && (
					<EventCardList lineItems={3}>
						<Skeleton height={340} style={{ marginTop: '60px'}} />
						<Skeleton height={340} style={{ marginTop: '60px'}} />
						<Skeleton height={340} style={{ marginTop: '60px'}} />
					</EventCardList>
				)}
			</Container>

			{!loadingUpcomingEvents && isMobile && upcomingEvents.onTheRise.length > 0 && (
				<>
					<Container>
						<SectionTitle text="Para curtir no fim de semana"  marginBottom={10} marginTop={20} size={20} />
					</Container>
					<SwiperContainer>
						<Swiper slidesPerView={1.25} loop={false} centeredSlides={true} spaceBetween={20}>
							{upcomingEvents.onTheRise.map(({
								name,
								banner,
								thumbnail,
								startDate,
								locationName,
								locationType,
								url,
								seatsMapActived,
								hasFacebookPixel,
							}: Event) => (
								<SwiperSlide>
									<EventCard
										key={url}
										name={name}
										banner={banner}
										thumbnail={thumbnail}
										startDate={startDate}
										locationName={locationName}
										locationType={locationType}
										url={url}
										seatsMapActived={seatsMapActived}
										short={true}
										main={false}
										hasFacebookPixel={hasFacebookPixel}
									/>
								</SwiperSlide>
							))}
						</Swiper>
					</SwiperContainer>
				</>
			)}
			{isMobile && loadingUpcomingEvents && (<Skeleton height={340} style={{ width: '90%', margin: '60px 5% 0'}} />)}

			<Container>
				{!loadingUpcomingEvents && !isMobile && upcomingEvents.otherEvents.length > 0 && (
					<>
						<SectionTitle text="Outros eventos"  marginBottom={10} marginTop={20} size={20} />
						<EventCardList lineItems={4}>
							{upcomingEvents.otherEvents.map(({
								name,
								banner,
								thumbnail,
								startDate,
								locationName,
								locationType,
								url,
								seatsMapActived,
								hasFacebookPixel,
							}: Event) => (
								<EventCard
									key={url}
									name={name}
									banner={banner}
									thumbnail={thumbnail}
									startDate={startDate}
									locationName={locationName}
									locationType={locationType}
									url={url}
									seatsMapActived={seatsMapActived}
									short={false}
									main={false}
									hasFacebookPixel={hasFacebookPixel}
								/>
							))}
						</EventCardList>
					</>
				)}

				{loadingUpcomingEvents && !isMobile && (
					<EventCardList lineItems={4}>
						<Skeleton height={340} style={{ marginTop: '60px' }} />
						<Skeleton height={340} style={{ marginTop: '60px' }} />
						<Skeleton height={340} style={{ marginTop: '60px' }} />
						<Skeleton height={340} style={{ marginTop: '60px' }} />
					</EventCardList>
				)}
			</Container>

			{!loadingUpcomingEvents && isMobile && upcomingEvents.otherEvents.length > 0 && (
				<>
					<Container>
						<SectionTitle text="Outros eventos"  marginBottom={10} marginTop={20} size={20} />
					</Container>
					<SwiperContainer>
						<Swiper slidesPerView={1.25} loop={true} centeredSlides={true} spaceBetween={20}>
							{upcomingEvents.otherEvents.map(({
								name,
								banner,
								thumbnail,
								startDate,
								locationName,
								locationType,
								url,
								seatsMapActived,
								hasFacebookPixel,
							}: Event) => (
								<SwiperSlide>
									<EventCard
										name={name}
										banner={banner}
										thumbnail={thumbnail}
										startDate={startDate}
										locationName={locationName}
										locationType={locationType}
										url={url}
										seatsMapActived={seatsMapActived}
										short={true}
										main={false}
										hasFacebookPixel={hasFacebookPixel}
									/>
								</SwiperSlide>
							))}
						</Swiper>
					</SwiperContainer>
				</>
			)}
			{isMobile && loadingUpcomingEvents && (<Skeleton height={340} style={{ width: '90%', margin: '60px 5% 0'}} />)}
		</>
	);
};

export default HomeUpcomingEvents;
