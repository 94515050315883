import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Calendar, Key, DollarSign } from 'react-feather';

import { dateGlobalPattern } from 'utils/dateUtils';
import { formatCurrency } from 'utils/currencyUtils';
import { IconWrapper } from 'styles';
import * as S from './styles';

const iconSize = 15;

type TicketCard = {
	ticket: {
		event: {
			name: string;
			fullAddress: string;
			locationType: string;
			startDate: string;
			thumbnail: string;
			customDate: string;
		};
		paymentStatus: string;
		purchaseCode: string;
		purchaseId: number;
		purchaseStatus: string;
		total: number;
	},
	isOrder: boolean,
	seeTicketsFn?: () => void,
	downloadAllTicketsFn?: () => void,
}

enum PURCHASE_STATUS {
	FINALIZED = 'FINALIZED',
	PENDING_AFFILIATE = 'PENDING_AFFILIATE',
	CANCELED = 'CANCELED',
}

enum PAYMENT_STATUS {
	CREATED = 'CREATED',
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	REFUNDED = 'REFUNDED',
	CANCELLED = 'CANCELLED',
	CHARGED_BACK = 'CHARGED_BACK',
}

const TicketCard = ({ ticket, isOrder, seeTicketsFn, downloadAllTicketsFn }: TicketCard) => {
	const [ status, setStatus ] = useState({ showTickets: false, statusLabel: '', statusClass: 'PENDING' });

	useEffect(() => {
		getStatusLayout(ticket.paymentStatus, ticket.purchaseStatus);
	}, [])

	const getStatusLayout = (payment: string, purchase: string) => {
		if ((purchase === PURCHASE_STATUS.FINALIZED) && (payment === PAYMENT_STATUS.APPROVED)) {
			setStatus({ showTickets: true, statusLabel: 'Aprovado', statusClass: 'APPROVED' });
			return;
		}

		if ((purchase === PURCHASE_STATUS.PENDING_AFFILIATE) && (payment === PAYMENT_STATUS.APPROVED)) {
			setStatus({ showTickets: true, statusLabel: 'Aprovado', statusClass: 'APPROVED' });
			return;
		}

		if ((purchase === PURCHASE_STATUS.FINALIZED) && (payment === null)) {
			setStatus({ showTickets: true, statusLabel: 'Aprovado', statusClass: 'APPROVED' });
			return;
		}

		if ((purchase === PURCHASE_STATUS.FINALIZED) && (payment === PAYMENT_STATUS.PENDING)) {
			setStatus({ showTickets: false, statusLabel: 'Pendente', statusClass: 'PENDING' });
			return;
		}

		if ((purchase === PURCHASE_STATUS.FINALIZED) && (payment === PAYMENT_STATUS.CREATED || payment === PAYMENT_STATUS.PENDING)) {
			setStatus({ showTickets: false, statusLabel: 'Pendente', statusClass: 'PENDING' });
			return;
		}

		if ((purchase === PURCHASE_STATUS.CANCELED) || (payment === PAYMENT_STATUS.REJECTED) || (payment === PAYMENT_STATUS.REFUNDED) || (payment === PAYMENT_STATUS.CANCELLED) || (payment === PAYMENT_STATUS.CHARGED_BACK)) {
			setStatus({ showTickets: false, statusLabel: 'Cancelado', statusClass: 'CANCELED' });
			return;
		}

		setStatus({ showTickets: false, statusLabel: 'Pendente', statusClass: 'PENDING' });
	}

	return (
		<S.TicketWrapper>
			<S.TicketEventImage thumbnail={ticket.event.thumbnail} />
			<S.TicketEventName>
				{ticket.event.name}
			</S.TicketEventName>

			<S.TicketInfoWrapper>
				<IconWrapper height={iconSize} width={iconSize}>
					<Calendar size={iconSize} />
				</IconWrapper>
				<S.Text>{(ticket.event.customDate && ticket.event.customDate.length > 0) ?  ticket.event.customDate : format(new Date(dateGlobalPattern(ticket.event.startDate)), 'dd/MM/yyyy HH:mm')}</S.Text>
			</S.TicketInfoWrapper>

			{isOrder && (
				<S.TicketInfoWrapper>
					<IconWrapper height={iconSize} width={iconSize}>
						<DollarSign size={iconSize} />
					</IconWrapper>
					<S.Text>Total: {formatCurrency(ticket.total)}</S.Text>
				</S.TicketInfoWrapper>
			)}

			{/* <S.TicketInfoWrapper>
				<IconWrapper height={iconSize} width={iconSize}>
					<MapPin size={iconSize} />
				</IconWrapper>
				<S.Text>{ticket.event.locationType === 'PHYSICAL' ? ticket.event.fullAddress : 'Virtual'}</S.Text>
			</S.TicketInfoWrapper> */}

			<S.TicketInfoWrapper>
				<IconWrapper height={iconSize} width={iconSize}>
					<Key size={iconSize} />
				</IconWrapper>
				<S.TextBold>Código da compra: {ticket.purchaseCode.toUpperCase()}</S.TextBold>
			</S.TicketInfoWrapper>

			<S.TicketActionWrapper>
				{status.showTickets && isOrder && (
					<S.TicketAction onClick={downloadAllTicketsFn}>
						<S.Text>Baixar ingresso(s)</S.Text>
					</S.TicketAction>
				)}

				{status.showTickets && !isOrder && (
					<S.TicketAction onClick={seeTicketsFn}>
						<S.Text>Ver ingresso(s)</S.Text>
					</S.TicketAction>
				)}

				<S.StatusBadge data-status={status.statusClass}>{status.statusLabel}</S.StatusBadge>
			</S.TicketActionWrapper>
		</S.TicketWrapper>
	);
}

export default TicketCard;
